:root {
    --color-primary-1: #0A2D82;
    --color-primary-1-50: #0A2D8280;
    --color-primary-2: #3F55A5;
    --color-primary-3: #0069D9;
    --color-primary-3-30: #0069D94D;
    --color-functional-danger-1: #B71C1C;
    --color-functional-danger-1-50: #B71C1C80;
    --color-functional-danger-2: #F44336;
    --color-functional-danger-3: #FF504D;
    --color-functional-danger-3-30: #FF504D4D;
    --color-functional-danger-4: #FFEBEE;
    --color-functional-warning-1: #FF9800;
    --color-functional-warning-2: #FFF3E0;
    --color-functional-positive-1: #8BC34A;
    --color-functional-positive-2: #F1F8E9;
    --color-neutral-gray-1: #202021;
    --color-neutral-gray-2: #454648;
    --color-neutral-gray-3: #848589;
    --color-neutral-gray-4: #D1D2D5;
    --color-neutral-gray-5: #EDEEF2;
    --color-neutral-gray-6: #F5F6FA;
    --color-neutral-white: #FFFFFF;
    --color-neutral-white-30: #FFFFFF80;
    --color-neutral-white-50: #FFFFFF4D;
    --color-complementary-yellow-1: #F1C21B;
}