/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";
@import 'variables';

/* Estricta fonts */

@font-face {
  font-family: 'Estricta-Black';
  src: url('./assets/fonts/Estricta/Estricta-Black.ttf') format('truetype');
};

@font-face {
  font-family: 'Estricta-BlackItalic';
  src: url('./assets/fonts/Estricta/Estricta-BlackItalic.ttf') format('truetype');
};

@font-face {
  font-family: 'Estricta-Bold';
  src: url('./assets/fonts/Estricta/Estricta-Bold.ttf') format('truetype');
};

@font-face {
  font-family: 'Estricta-BoldItalic';
  src: url('./assets/fonts/Estricta/Estricta-BoldItalic.ttf') format('truetype');
};

@font-face {
  font-family: 'Estricta-Light';
  src: url('./assets/fonts/Estricta/Estricta-Light.ttf') format('truetype');
};

@font-face {
  font-family: 'Estricta-LightItalic';
  src: url('./assets/fonts/Estricta/Estricta-LightItalic.ttf') format('truetype');
};

@font-face {
  font-family: 'Estricta-Medium';
  src: url('./assets/fonts/Estricta/Estricta-Medium.ttf') format('truetype');
};

@font-face {
  font-family: 'Estricta-MediumItalic';
  src: url('./assets/fonts/Estricta/Estricta-MediumItalic.ttf') format('truetype');
};

@font-face {
  font-family: 'Estricta-Regular';
  src: url('./assets/fonts/Estricta/Estricta-Regular.ttf') format('truetype');
};

@font-face {
  font-family: 'Estricta-RegularItalic';
  src: url('./assets/fonts/Estricta/Estricta-RegularItalic.ttf') format('truetype');
};

@font-face {
  font-family: 'Estricta-Thin';
  src: url('./assets/fonts/Estricta/Estricta-Thin.ttf') format('truetype');
};

@font-face {
  font-family: 'Estricta-ThinItalic';
  src: url('./assets/fonts/Estricta/Estricta-ThinItalic.ttf') format('truetype');
};


/* Roboto fonts */

@font-face {
  font-family: 'Roboto-Black';
  src: url('./assets/fonts/Roboto/Roboto-Black.ttf') format('truetype');
};

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url('./assets/fonts/Roboto/Roboto-BlackItalic.ttf') format('truetype');
};

@font-face {
  font-family: 'Roboto-Bold';
  src: url('./assets/fonts/Roboto/Roboto-Bold.ttf') format('truetype');
};

@font-face {
  font-family: 'Roboto-BoldCondensed';
  src: url('./assets/fonts/Roboto/Roboto-BoldCondensed.ttf') format('truetype');
};

@font-face {
  font-family: 'Roboto-BoldCondensedItalic';
  src: url('./assets/fonts/Roboto/Roboto-BoldCondensedItalic.ttf') format('truetype');
};

@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url('./assets/fonts/Roboto/Roboto-BoldItalic.ttf') format('truetype');
};

@font-face {
  font-family: 'Roboto-Condensed';
  src: url('./assets/fonts/Roboto/Roboto-Condensed.ttf') format('truetype');
};

@font-face {
  font-family: 'Roboto-CondensedItalic';
  src: url('./assets/fonts/Roboto/Roboto-CondensedItalic.ttf') format('truetype');
};

@font-face {
  font-family: 'Roboto-Italic';
  src: url('./assets/fonts/Roboto/Roboto-Italic.ttf') format('truetype');
};

@font-face {
  font-family: 'Roboto-Light';
  src: url('./assets/fonts/Roboto/Roboto-Light.ttf') format('truetype');
};

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url('./assets/fonts/Roboto/Roboto-LightItalic.ttf') format('truetype');
};

@font-face {
  font-family: 'Roboto-Medium';
  src: url('./assets/fonts/Roboto/Roboto-Medium.ttf') format('truetype');
};

@font-face {
  font-family: 'Roboto-MediumItalic';
  src: url('./assets/fonts/Roboto/Roboto-MediumItalic.ttf') format('truetype');
};

@font-face {
  font-family: 'Roboto-Regular';
  src: url('./assets/fonts/Roboto/Roboto-Regular.ttf') format('truetype');
};

@font-face {
  font-family: 'Roboto-Thin';
  src: url('./assets/fonts/Roboto/Roboto-Thin.ttf') format('truetype');
};

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url('./assets/fonts/Roboto/Roboto-ThinItalic.ttf') format('truetype');
};

.main-container-content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1366px) {
  .preview-draft-dialog {
    width: 90vw !important;
    max-width: 90vw !important;
  }
}

.mdc-tooltip__surface {
  background-color: #0069D9 !important;
  color: white;
  font-size: 14px;
  padding: 16px;
  width: 228px !important;
}

.mdc-checkbox__native-control:focus:focus:checked~.mdc-checkbox__background, .mdc-checkbox__native-control:focus:focus:indeterminate~.mdc-checkbox__background {
  border-color: #3f51b5 !important;
  background-color: #3f51b5 !important;
}

.mdc-checkbox__native-control:focus:focus:not(:checked)~.mdc-checkbox__background, .mdc-checkbox__native-control:focus:focus:not(:indeterminate)~.mdc-checkbox__background {
  border-color: #3f51b5 !important;
}

.mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background, .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
  border-color: #3f51b5 !important;
  background-color: #3f51b5 !important;
}

.mat-mdc-checkbox-ripple, .mdc-checkbox__ripple {
 display: none !important;
}
